import React from "react";
import Layout from "../../../other/layout";
import SalesMaterial from "../../../components/AC-StaticPages/sales-resources/sales-material/SalesMaterial";
import SEO from "../../../other/seo";

export default function salesMaterial() {
  return (
    <Layout>
      <SEO title="Sales Material" />

      <SalesMaterial />
    </Layout>
  );
}
