import React from "react";
import { Link } from "gatsby";
import SalesMaterials_Hero from "../../../../assets/img/Canada T0_Resources-SalesTools.jpg";
import product_sell_sheets from "../../../../assets/img/Product_sell_sheet.jpg";
import catalogues from "../../../../assets/img/Canada Catalogues.jpg";

import styles from "../../style/styles.module.css";

export default function SalesMaterials() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Sales Material</h1>
          <h2>Printed sales materials to close deals.</h2>
          <img
            className={styles.hero}
            src={SalesMaterials_Hero}
            alt="Tools that drive revenue. Grow your business with Free sales materials Image"
          />

          <p className={styles.productMargin}>
            Attractive sales support materials: full-colour catalogues,
            brochures, sell sheets and more.
          </p>
          <p className={styles.productMargin}>
            Let customers know what you offer them and how to contact you.
          </p>
          <p className={styles.productMargin}>
            Sales kits: These feature product information and samples, plus
            sales tips to help you move more product.
          </p>
          <hr className={styles.productHr} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Printed Sales Material Categories</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img
                src={product_sell_sheets}
                alt="Product sell sheets Image"
              ></img>
              <Link to="/sales-resources/sales-material/sell-sheets/">
                Product Sell Sheets
              </Link>
            </div>
            <div className={styles.card}>
              <img src={catalogues} alt="Catalogues Image"></img>
              <Link to="/sales-resources/sales-material/catalogues/">
                Catalogues
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
